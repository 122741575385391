import React from "react"
import { graphql } from "gatsby"

import Events, { Props } from "./events"

const allEvents = (props: Props) => <Events {...props} />

export default allEvents

export const query = graphql`
  query AllEventsPageQuery {
    allContentfulEventCategory {
      edges {
        node {
          id
          title
        }
      }
    }
    allContentfulCategory {
      edges {
        node {
          id
          name
          slug
          order
          image {
            title
            fluid(quality: 90) {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    }
    contentfulEventsPage {
      title
      slug
      description
      image {
        title
        fluid(quality: 90, maxWidth: 2000) {
          ...GatsbyContentfulFluid
        }
      }
    }
    allContentfulEvents(
      sort: { fields: [order, date], order: [ASC, DESC] }
      filter: { parentEvent: { title: { eq: null } } }
    ) {
      edges {
        node {
          id
          title
          date
          endDate
          customDate
          eventCategory {
            id
            title
          }
          content {
            content
          }
          image {
            title
            fluid(quality: 90, maxWidth: 1000) {
              ...GatsbyContentfulFluid
            }
          }
          parentEvent {
            title
            slug
          }
          externalLink
          ticketLink
          freeEvent
          ongoing
          slug
          isFuture
          shortDescription
        }
      }
    }
  }
`
