import styled from "styled-components"
import { COLORS, FONTS, MOBILE_MAX_WIDTH } from "../../styles"
import DownArrowIcon from "../../images/down-arrow.svg"
import ArrowRightWhite from "../../images/arrow-right.svg"
import ArrowRightGreen from "../../images/arrow-right.svg"
import PlusGreen from "../../images/plus.svg"
import MinusGreen from "../../images/minus.svg"
import XGreen from "../../images/x.svg"
import {
  H2,
  H3,
  H4,
  H5,
  S1,
  S2,
  T1,
  T3,
  T4,
  BaseH2,
  H1,
} from "../../components/common/text"
import Button from "../../components/common/button"
import { SyntheticEvent } from "react"
import { AutoLink } from "../../components/link"

export default {
  SectionTitle: styled(H4)`
    width: 100%;
    background-color: ${COLORS.AQUA_HAZE};
    height: 60px;
    text-align: center;
    padding-top: 10px;
  `,
  SectionContainer: styled.div`
    display: flex;
    flex-direction: column;
    padding: 0rem 3rem;
    @media screen and (max-width: ${MOBILE_MAX_WIDTH}px) {
      padding: 0 1.6rem;
      padding-top: 7px;
    }
  `,
  SectionContentContainer: styled.div`
    display: flex;
    flex-direction: row;
    border-bottom-color: ${COLORS.BOTTLE_GREEN};
    border-bottom-width: 1px;
    border-bottom-style: solid;

    :last-child {
      border-bottom: none;
    }

    @media screen and (max-width: ${MOBILE_MAX_WIDTH}px) {
      flex-direction: column;
    }
  `,
  BigDateContainer: styled.div`
    flex: 1;
    padding-top: 23px;
  `,
  EventsContainer: styled.div`
    display: flex;
    flex-direction: column;
    flex: 3;
  `,
  SeeMoreButton: styled(Button)`
    width: calc(100% - 6rem);
    margin: 50px 3rem;
    height: 50px;
    @media screen and (max-width: ${MOBILE_MAX_WIDTH}px) {
      margin: 23px 1.6rem 35px 1.6rem;
      width: calc(100% - 3.2rem);
    }
  `,
  BigNumber: styled(H1)`
    margin-bottom: 15px;
  `,
  DayOfWeek: styled(T1)`
    margin-top: 10px;
  `,
  FiltersContainer: styled.div`
    position: sticky;
    top: 20px;
    z-index: 4;
    @media screen and (max-width: ${MOBILE_MAX_WIDTH}px) {
      top: 60px;
    }
  `,
  Filters: styled.div`
    font-family: ${FONTS.SOHNE_FONT_FAMILY};
    padding: 20px 30px;
    display: flex;
    background: ${COLORS.WHITE};
    flex-direction: row;
    align-items: flex-start;
    @media screen and (max-width: ${MOBILE_MAX_WIDTH}px) {
      flex-direction: column;
      align-items: baseline;
    }
    .select {
      @media screen and (max-width: ${MOBILE_MAX_WIDTH}px) {
        width: 100%;
        margin-left: 0px;
        margin-top: 15px;
      }
    }
    border-bottom: 1px solid ${COLORS.BOTTLE_GREEN};
  `,
  FiltersControl: styled.button.attrs({
    onMouseDown: (e: SyntheticEvent) => {
      //Prevent focus on click
      e.nativeEvent.preventDefault()
    },
  })`
    font-family: ${FONTS.SOHNE_FONT_FAMILY};
    font-weight: 400;
    font-size: 12px;
    line-height: 1.25;
    letter-spacing: 1.44px;
    text-transform: uppercase;
    background: ${COLORS.CHALK_VIOLET};
    text-align: center;
    height: 40px;
    padding: 12px 0;
    text-decoration: none;
    cursor: pointer;
    border: none;
    width: 100%;
    ${({ isClose }) =>
      isClose &&
      `
      border-bottom: 1px solid ${COLORS.BOTTLE_GREEN};
  `};
  `,

  HideAndShow: styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  `,
  PlusGreen: styled(PlusGreen)`
    height: 8px;
    margin-left: 5px;
    margin-top: 2px;
    fill: ${COLORS.BOTTLE_GREEN};
  `,
  MinusGreen: styled(MinusGreen)`
    height: 8px;
    margin-left: 5px;
    margin-top: 2px;
    fill: ${COLORS.BOTTLE_GREEN};
  `,
  FilterTitle: styled(S1)`
    margin-top: 12px;
    margin-right: 27px;
    @media screen and (max-width: ${MOBILE_MAX_WIDTH}px) {
      margin-bottom: 15px;
      padding-top: 0px;
    }
  `,
  Category: styled.div`
    font-family: ${FONTS.SOHNE_LEICHT};
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-left: 50px;
    @media screen and (max-width: ${MOBILE_MAX_WIDTH}px) {
      flex-direction: column;
      margin-left: 0;
      margin-top: 20px;
      width: 100%;
    }
  `,
  ChipContainer: styled.div`
    display: flex;
    flex-flow: row wrap;
    margin-top: -6px;
  `,
  Chip: styled.div`
    height: 25px;
    background-color: ${COLORS.BEIGE};
    padding: 6px 6px;
    cursor: pointer;
    margin-right: 12px;
    margin-top: 12px;
    display: flex;
    align-items: center;
  `,
  ChipText: styled(T3)`
    font-weight: 400;
  `,
  XRemove: styled(XGreen)`
    margin-left: 3px;
    width: 7px;
    height: 7px;
    fill: ${COLORS.BOTTLE_GREEN};
  `,
  TickerSection: styled.div`
    background-color: ${COLORS.HOLLY_GREEN};
    @media screen and (max-width: ${MOBILE_MAX_WIDTH}px) {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: calc(90vh - 25px);
    }
  `,
  TickerSoonSection: styled.div`
    background-color: ${COLORS.AQUA_HAZE};
    padding-bottom: 30px;

    @media screen and (max-width: ${MOBILE_MAX_WIDTH}px) {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 75vh;
      padding-bottom: 15px;
    }
  `,
  // Calendar ticker elements
  TickerElement: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 790px;
    margin-right: 1rem;
    padding-top: 20px;
    height: 530px;
    @media screen and (max-width: ${MOBILE_MAX_WIDTH}px) {
      width: 80%;
      height: 76vh;
      padding-top: 0px;
    }
  `,
  EventImageContainer: styled(AutoLink)`
    display: flex;
    flex-direction: column;
    flex-grow: 5;
    height: 100%;
    max-height: 440px;
    overflow: hidden;
    @media screen and (max-width: ${MOBILE_MAX_WIDTH}px) {
      max-height: 68vh;
    }
  `,
  EventBottomContainer: styled.div`
    display: flex;
    flex-direction: column;
  `,
  EventSoonImageContainer: styled(AutoLink)`
    display: flex;
    flex-direction: column;
    flex-grow: 5;
    height: 100%;
    max-height: 460px;
    overflow: hidden;
    @media screen and (max-width: ${MOBILE_MAX_WIDTH}px) {
      max-height: 85vh;
      width: 95%;
    }
  `,
  EventSoonBottomContainer: styled.div`
    display: flex;
    flex-direction: column;
  `,
  TickerSoonElement: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 690px;
    margin-right: 1rem;
    height: 530px;
    @media screen and (max-width: ${MOBILE_MAX_WIDTH}px) {
      width: 80%;
      height: 66vh;
      padding-top: 0px;
    }
  `,
  EventStartDateComponents: styled(H2)`
    @media screen and (max-width: ${MOBILE_MAX_WIDTH}px) {
      margin-bottom: 10px;
      letter-spacing: 4px;
      margin-right: 5px;
    }
  `,
  EventSoonStartDateComponents: styled(H2)`
    @media screen and (max-width: ${MOBILE_MAX_WIDTH}px) {
      width: 2rem;
      margin-bottom: 10px;
      margin-right: 5px;
    }
  `,
  EventStartDate: styled.div`
    position: absolute;
    z-index: 1;
    left: calc(100% - 110px - 1.75rem);
    top: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media screen and (max-width: ${MOBILE_MAX_WIDTH}px) {
      left: calc(100% - 53px - 3rem);
      right: 0rem;
      top: 10px;
    }
  `,
  EventSoonStartDate: styled.div`
    position: absolute;
    z-index: 1;
    right: 30px;
    top: -0.2vw;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media screen and (max-width: ${MOBILE_MAX_WIDTH}px) {
      right: 1.6rem;
      top: 0.1rem;
    }
  `,
  EventEndDate: styled(S1)`
    margin-top: 15px;
  `,
  EventSoonEndDate: styled(S1)`
    margin-top: 15px;
  `,
  EventTickerTitle: styled(H4)`
    margin-top: 5px;
    width: 580px;
    margin-bottom: 15px;
    @media screen and (max-width: ${MOBILE_MAX_WIDTH}px) {
      width: 90%;
    }
  `,
  EventSoonTickerTitle: styled(H4)`
    margin-bottom: 20px;
    width: 80%;
  `,

  SoonTitle: styled(S2)`
    margin-top: 20px;
    border-top: 1px solid ${COLORS.HOLLY_GREEN};
  `,
  SectionSoonTitleContainer: styled.div`
    width: 100%;
    background-color: ${COLORS.AQUA_HAZE};
    height: 120px;
    display: flex;
    place-items: center;
    justify-content: center;
    @media screen and (max-width: ${MOBILE_MAX_WIDTH}px) {
      height: 90px;
    }
  `,
  SectionSoonTitle: styled(S2)``,
  RangeDateInput: styled.button.attrs({
    onMouseDown: (e: SyntheticEvent) => {
      //Prevent focus on click
      e.nativeEvent.preventDefault()
    },
  })`
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: space-between;
    border: 0.8px solid ${COLORS.HOLLY_GREEN};
    height: 40px;
    padding: 7.5px 8px 7.5px 18px;
    font-family: ${FONTS.SOHNE_FONT_FAMILY};
    font-weight: 300;
    font-size: 15px;
    line-height: 1.33;
    letter-spacing: 0.15px;
    background: none;
    width: 100%;
  `,
  RangeDate: styled.div`
    width: 285px;
    @media screen and (max-width: ${MOBILE_MAX_WIDTH}px) {
      width: 100%;
    }
  `,
  RangeCalendar: styled.div`
    position: static;
    z-index: 2;
    width: 285px;
    border-bottom: 1px solid ${COLORS.HOLLY_GREEN};
    border-left: 1px solid ${COLORS.HOLLY_GREEN};
    &.rdrCalendarWrapper {
      color: red;
    }
    .rdrMonthAndYearPickers {
      font-family: ${FONTS.SOHNE_FONT_FAMILY};
      font-size: 15px;
      font-weight: 400;
      line-height: 1.2;
      letter-spacing: 0.15px;
    }
    .rdrDay,
    .rdrWeekDay {
      font-family: ${FONTS.SOHNE_FONT_FAMILY};
      color: ${COLORS.HOLLY_GREEN};
      border-top: 1px solid ${COLORS.HOLLY_GREEN};
      border-right: 1px solid ${COLORS.HOLLY_GREEN};
    }

    .rdrMonthAndYearWrapper {
      border-right: 1px solid ${COLORS.HOLLY_GREEN};
    }

    @media screen and (max-width: ${MOBILE_MAX_WIDTH}px) {
      width: 100%;
    }
  `,
  ArrowRightWhiteIcon: styled(ArrowRightWhite)`
    height: 0.5rem;
    margin-bottom: 2px;
    fill: ${COLORS.WHITE};
  `,
  ArrowRightGreenIcon: styled(ArrowRightGreen)`
    height: 0.5rem;
    fill: ${COLORS.BOTTLE_GREEN};
  `,
  Arrow: styled(DownArrowIcon)`
    height: 12px;
    margin-right: 4px;
    ${props => props.isopen && "transform: rotate(180deg);"}
  `,
  Empty: styled(H5)`
    padding: 20px 0;
    text-align: center;
  `,
  BodyContent: styled.div`
    position: relative;
    z-index: 0;
  `,
}
