import React, { FunctionComponent, useEffect, useRef, useState } from "react"
import "react-date-range/dist/styles.css"
import "react-date-range/dist/theme/default.css"
import Layout from "../../components/layout/layout"
import SEO from "../../components/common/seo"
import useOnClickOutside from "../../components/useOnClickOutside"
import Style from "./events.style"
import "../style.css"
import { DateRange } from "react-date-range"
import {
  Event,
  EventCategory,
  stringifyDate,
} from "../../services/eventsService"
import EventCard from "../../components/event/eventCard"
import dayjs, { Dayjs } from "dayjs"
import Ticker from "../../components/common/ticker"
import TickerStyle from "../../components/common/ticker.style"
import ScrollDown from "../../components/scrollDown"
import { eventsDropdownStyle } from "../../components/explore/dropdown.style"
import Select, { components } from "react-select"
import DownArrowIcon from "../../images/down-arrow.svg"
import { flow, groupBy, last, sortBy } from "lodash/fp"
import { map } from "lodash"
import { PageProps } from "gatsby"
import { CTA } from "../../components/common/cta"
import { COLORS } from "../../styles"

interface Data {
  allContentfulEventCategory: {
    edges: {
      node: {
        id: string
        title: string
      }
    }[]
  }
  contentfulEventsPage: {
    title: string
    description?: string
    image: {
      title: string
      fluid: any
    }
    slug: string
  }
  allContentfulEvents: {
    edges: {
      node: Event
    }[]
  }
}

interface LocationState {
  filter: {
    state: Array<any>
    selectedCategory: Item[]
  }
}

interface Item {
  label: string
  value: string
}

interface EventTickerCardProps {
  event: Event
  categorySlug: string
}

//TODO: move this component to a separate file
const EventTickerCard: FunctionComponent<EventTickerCardProps> = ({
  event,
  categorySlug,
}) => {
  return (
    <Style.TickerElement>
      <Style.EventImageContainer to={`/${categorySlug}/${event.slug}`}>
        <TickerStyle.TopCarouselImages.HighlightSpan />
        {event.image && (
          <TickerStyle.TopCarouselImages.TickerImage
            fluid={event.image.fluid}
            alt={event.image.title}
          />
        )}
      </Style.EventImageContainer>

      <Style.EventStartDate>
        <Style.EventStartDateComponents textColor="white" date={event.date} />
      </Style.EventStartDate>
      <Style.EventBottomContainer>
        <Style.EventEndDate $textColor="white">
          {event.ongoing
            ? "Ongoing"
            : event.customDate ||
              (event.endDate && stringifyDate(event.endDate))}
        </Style.EventEndDate>
        <Style.EventTickerTitle $textColor={COLORS.WHITE}>
          {event.title}
        </Style.EventTickerTitle>
        <CTA id="ctaId" color="white" to={`/${categorySlug}/${event.slug}`}>
          Learn More
        </CTA>
      </Style.EventBottomContainer>
    </Style.TickerElement>
  )
}

//TODO: this component looks similar to the EventTickerCard, we can use single component for both cases
const EventSoonTickerCard: FunctionComponent<EventTickerCardProps> = ({
  event,
  categorySlug,
}) => {
  return (
    <Style.TickerSoonElement>
      <Style.EventSoonStartDate>
        <Style.EventSoonStartDateComponents
          textColor={COLORS.HOLLY_GREEN}
          date={event.date}
        />
      </Style.EventSoonStartDate>
      <Style.EventSoonImageContainer to={`/${categorySlug}/${event.slug}`}>
        <TickerStyle.TopCarouselImages.HighlightSpan />
        {event.image && (
          <TickerStyle.TopCarouselImages.TickerImage
            fluid={event.image.fluid}
            alt={event.image.title}
          />
        )}
      </Style.EventSoonImageContainer>
      <Style.EventSoonBottomContainer>
        <Style.EventSoonEndDate $textColor={COLORS.HOLLY_GREEN}>
          Through {event.endDate && stringifyDate(event.endDate)}
        </Style.EventSoonEndDate>
        <Style.EventSoonTickerTitle $textColor={COLORS.HOLLY_GREEN}>
          {event.title}
        </Style.EventSoonTickerTitle>
        <CTA id="ctaId" to={`/${categorySlug}/${event.slug}`}>
          Learn More
        </CTA>
      </Style.EventSoonBottomContainer>
    </Style.TickerSoonElement>
  )
}

interface SectionProps {
  events: Event[]
  eventsPageSlug: string
  title: string
  state: any
  isOngoing: boolean
  eventsRef?: any
}

//TODO: move this component to a separate file
const EventsSection: FunctionComponent<SectionProps> = props => {
  const { events, title, eventsPageSlug, eventsRef, state, isOngoing } = props
  const eventsByDay = flow(
    sortBy("date"),
    groupBy(
      (event: Event) => dayjs(event.date).day() && !event.title.includes("test")
    )
  )(events)

  return (
    <>
      <Style.SectionTitle ref={eventsRef}>{title}</Style.SectionTitle>
      <Style.SectionContainer>
        {events.length === 0 ? (
          <Style.Empty>No event planned. Come back soon!</Style.Empty>
        ) : (
          <>
            {isOngoing && (
              <Style.SectionContentContainer>
                <Style.BigDateContainer />
                <Style.EventsContainer>
                  {events.map((event: Event) => (
                    <EventCard
                      state={state}
                      key={event.id}
                      card={event}
                      eventsPageSlug={eventsPageSlug}
                      isOngoing={true}
                    />
                  ))}
                </Style.EventsContainer>
              </Style.SectionContentContainer>
            )}
            {!isOngoing &&
              map(eventsByDay, (events: Event[]) => (
                <Style.SectionContentContainer key={events[0].id}>
                  <Style.BigDateContainer>
                    <Style.BigNumber>
                      {dayjs(events[0].date).format("DD")}
                    </Style.BigNumber>
                    <Style.DayOfWeek>
                      {dayjs(events[0].date).format("dddd")}
                    </Style.DayOfWeek>
                  </Style.BigDateContainer>
                  <Style.EventsContainer>
                    {events.map((event: Event) => (
                      <EventCard
                        state={state}
                        key={event.id}
                        card={event}
                        eventsPageSlug={eventsPageSlug}
                        isOngoing={false}
                      />
                    ))}
                  </Style.EventsContainer>
                </Style.SectionContentContainer>
              ))}
          </>
        )}
      </Style.SectionContainer>
    </>
  )
}

const getEventsForMonth = (month: Dayjs, events: Event[]) =>
  events.filter(event => {
    const hasEventStartedEndOfMonth =
      !event.date || dayjs(event.date) < month.add(1, "month")
    const hasEventEndedStartOfMonth =
      !event.endDate || dayjs(event.endDate) < month
    return hasEventStartedEndOfMonth && !hasEventEndedStartOfMonth
  })

const DropdownIndicator = (props: any) => {
  return (
    <components.DropdownIndicator {...props}>
      <DownArrowIcon height="12px" />
    </components.DropdownIndicator>
  )
}
const EventsPage: FunctionComponent<PageProps<
  Data,
  any,
  LocationState
>> = props => {
  const { location, data } = props
  const eventsRef = useRef(null)
  const { title, slug, description } = data.contentfulEventsPage
  const [isAllOngoing, setAllOngoing] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [isShowFilter, setIsShowFilter] = useState(true)
  //TODO: refacto
  const [selectedCategory, setSelectedCategory] = useState(
    location.state?.selectedCategory || []
  )
  const [monthsToDisplay, setMonthsToDisplay] = useState([
    dayjs().startOf("month"),
  ])
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: null,
      key: "selection",
    },
  ])
  useEffect(() => {
    if (location.state && location.state.filter) {
      const { state, selectedCategory } = location.state.filter
      setState(state)
      setSelectedCategory(selectedCategory)
    }
  }, [])
  const ref = useRef()
  useOnClickOutside(ref, () => setIsOpen(false))

  function categoryExist(categories: EventCategory[]) {
    let bool = false
    const data = [...new Set(selectedCategory.map(item => item.value))]
    categories.map(item => {
      if (data.includes(item.title)) {
        bool = true
      }
    })
    return bool
  }

  let events = data.allContentfulEvents.edges.map(edge => edge.node)
  const eventCategories = data.allContentfulEventCategory.edges.map(node => ({
    value: node.node.title,
    label: node.node.title,
  }))
  const globalComingSoonEvents = events.filter(event => {
    return !event.ongoing && dayjs(event.date).isAfter(dayjs(new Date()))
  })

  function getEvents() {
    return events.filter(
      (event: any) =>
        !event.ongoing &&
        event.eventCategory &&
        (categoryExist(event.eventCategory) || selectedCategory.length === 0) &&
        ((dayjs(event.endDate) > dayjs(state[0].startDate) &&
          dayjs(event.date) < dayjs(state[0].endDate)) ||
          state[0].endDate === null)
    )
  }

  function globalOngoingEvents() {
    return events.filter(
      (event: Event) =>
        event.ongoing &&
        event.eventCategory &&
        (categoryExist(event.eventCategory) || selectedCategory.length === 0) &&
        ((dayjs(event.endDate) > dayjs(state[0].startDate) &&
          dayjs(event.date) < dayjs(state[0].endDate)) ||
          state[0].endDate === null)
    )
  }

  function globalCurrentEvents() {
    return events.filter(
      (event: Event) => !dayjs(event.date).isAfter(dayjs(new Date()))
    )
  }

  function handleChange(selected: any) {
    if (selected) {
      setSelectedCategory(selected)
    }
  }

  function removeCategory(value: any) {
    const data = selectedCategory.filter((item: Item) => item.value !== value)
    setSelectedCategory(data)
  }

  return (
    <Layout>
      <SEO title={title} description={description} />
      <Style.TickerSection>
        <Ticker>
          {globalCurrentEvents().map(event => (
            <EventTickerCard
              event={event}
              categorySlug={slug}
              key={event.slug}
            />
          ))}
        </Ticker>
      </Style.TickerSection>
      <ScrollDown goToRef={eventsRef} />
      <div>
        <Style.FiltersContainer>
          <Style.FiltersControl
            isClose={!isShowFilter}
            onClick={() => setIsShowFilter(!isShowFilter)}
          >
            <Style.HideAndShow>
              {!isShowFilter ? (
                <>
                  Show Filters <Style.PlusGreen />
                </>
              ) : (
                <>
                  Hide Filters <Style.MinusGreen />
                </>
              )}
            </Style.HideAndShow>
          </Style.FiltersControl>
          {isShowFilter && (
            <Style.Filters>
              <Style.FilterTitle>Filter By</Style.FilterTitle>
              <Style.RangeDate ref={ref}>
                <Style.RangeDateInput onClick={() => setIsOpen(!isOpen)}>
                  <span>
                    {state[0].endDate === null
                      ? "Date"
                      : dayjs(state[0].startDate).format("MMM D, YYYY") +
                        " - " +
                        dayjs(state[0].endDate).format("MMM D, YYYY")}
                  </span>
                  <Style.Arrow isopen={isOpen} />
                </Style.RangeDateInput>
                {isOpen && (
                  <Style.RangeCalendar>
                    <DateRange
                      onChange={(item: any) => setState([item.selection])}
                      ranges={state}
                      showMonthAndYearPickers={false}
                      showSelectionPreview={false}
                      rangeColors={["#c7dce6"]}
                      color={"#083025"}
                      showDateDisplay={false}
                      minDate={new Date()}
                    />
                  </Style.RangeCalendar>
                )}
              </Style.RangeDate>
              <Select
                value={selectedCategory}
                styles={eventsDropdownStyle}
                controlShouldRenderValue={false}
                hideSelectedOptions={false}
                closeMenuOnSelect={false}
                components={{ DropdownIndicator }}
                isClearable={false}
                isSearchable={false}
                isMulti
                placeholder="Category"
                options={eventCategories}
                onChange={handleChange}
                className="select"
              />
              <Style.Category>
                <Style.FilterTitle>Filtered By:</Style.FilterTitle>
                <Style.ChipContainer>
                  {selectedCategory &&
                    selectedCategory.map((item: Item) => {
                      return (
                        <Style.Chip
                          onClick={() => removeCategory(item.value)}
                          key={item.value}
                        >
                          <Style.ChipText>
                            {item.label.toUpperCase()}
                          </Style.ChipText>
                          <Style.XRemove />
                        </Style.Chip>
                      )
                    })}
                </Style.ChipContainer>
              </Style.Category>
            </Style.Filters>
          )}
        </Style.FiltersContainer>

        <Style.BodyContent>
          {
            <EventsSection
              isOngoing={true}
              events={globalOngoingEvents().slice(
                0,
                isAllOngoing ? undefined : 2
              )}
              title="Ongoing"
              eventsPageSlug={slug}
              state={{ selectedCategory }}
              eventsRef={eventsRef}
            />
          }
          {!isAllOngoing && globalOngoingEvents().length > 2 && (
            <Style.SeeMoreButton
              icon="rightPlus"
              mode="button"
              onClick={() => {
                setAllOngoing(true)
              }}
            >
              See More
            </Style.SeeMoreButton>
          )}

          {monthsToDisplay.map(month => (
            <EventsSection
              isOngoing={false}
              events={getEventsForMonth(month, getEvents())}
              title={month.format("MMMM")}
              eventsPageSlug={slug}
              key={month.format("MM")}
              filter={{ state: state, selectedCategory: selectedCategory }}
            />
          ))}
          {monthsToDisplay.length <= 12 && (
            <Style.SeeMoreButton
              onClick={() => {
                setMonthsToDisplay(months => [
                  ...months,
                  last(months)!.add(1, "month"),
                ])
              }}
            >
              See More +
            </Style.SeeMoreButton>
          )}
        </Style.BodyContent>
      </div>
      <>
        <Style.SectionSoonTitleContainer>
          <Style.SectionSoonTitle>Upcoming Events</Style.SectionSoonTitle>
        </Style.SectionSoonTitleContainer>
        {globalComingSoonEvents.length === 0 ? (
          <Style.Empty>No upcoming events. Come back soon!</Style.Empty>
        ) : (
          <Style.TickerSoonSection>
            <Ticker>
              {globalComingSoonEvents.map(event => (
                <EventSoonTickerCard
                  event={event}
                  categorySlug={slug}
                  key={event.slug}
                />
              ))}
            </Ticker>
          </Style.TickerSoonSection>
        )}
      </>
    </Layout>
  )
}

export default EventsPage
