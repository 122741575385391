import React, { useState } from "react"
import Style from "./eventCard.style"
import { Event, stringifyDate } from "../../services/eventsService"
import { COLORS } from "../../styles"

interface Props {
  card: Event
  className?: string
  eventsPageSlug: string
  state: any
  isOngoing?: boolean
}

const EventCard = ({
  card,
  className,
  eventsPageSlug,
  state,
  isOngoing,
}: Props) => {
  const [showDescription, setShowDescription] = useState(false)
  return (
    <Style.ResetLink to={`/${eventsPageSlug}/${card.slug}`} state={state}>
      <Style.Container
        className={className}
        onMouseEnter={() => setShowDescription(true)}
        onMouseLeave={() => setShowDescription(false)}
      >
        <Style.ImageWrapper>
          <Style.ImageSizer />
          {card.image && (
            <Style.Image fluid={card.image.fluid} alt={card.image.title} />
          )}
        </Style.ImageWrapper>
        <Style.Content>
          {!isOngoing && (
            <Style.Dates>
              {card.customDate
                ? card.customDate
                : "Now through " + stringifyDate(card.endDate)}
            </Style.Dates>
          )}
          {isOngoing && <Style.Dates $textColor="green">Ongoing</Style.Dates>}
          <Style.Title $textColor="green">{card.title}</Style.Title>
          {showDescription && card.shortDescription && (
            <Style.Description $textColor={COLORS.CHARCOAL}>
              {card.shortDescription}
            </Style.Description>
          )}
          <Style.Footer>
            {card.eventCategory &&
              card.eventCategory.map(cat => (
                <Style.Category key={cat.id}>{cat.title}</Style.Category>
              ))}
            {card.freeEvent && <Style.Free>Free</Style.Free>}
            {!card.freeEvent && card.ticketLink && (
              <Style.Ticket
                onClick={event => {
                  event.stopPropagation()
                  window.open(card.ticketLink, "_blank")
                  event.stopPropagation()
                }}
              >
                <Style.TicketIcon />
                <Style.TicketText>Ticket Required</Style.TicketText>
              </Style.Ticket>
            )}
          </Style.Footer>
        </Style.Content>
      </Style.Container>
    </Style.ResetLink>
  )
}

export default EventCard
