import styled from "styled-components"
import Img from "gatsby-image"
import { COLORS, MOBILE_MAX_WIDTH } from "../../styles"
import TicketIcon from "../../images/ticket.svg"
import { AutoLink } from "../link"
import { H5, S1, T1, T3, T4 } from "../common/text"

export default {
  Container: styled.div`
    display: flex;
    flex-direction: row;
    flex: 1;
    padding: 1.25rem 0rem;
    @media screen and (max-width: ${MOBILE_MAX_WIDTH}px) {
      flex-direction: column;
      padding-top: 0rem;
    }
  `,
  ImageWrapper: styled.div`
    width: 285px;
    height: 160px;
    margin-right: 1.875rem;
    position: relative;
    @media screen and (max-width: ${MOBILE_MAX_WIDTH}px) {
      width: 100%;
      margin-right: 0;
      margin-bottom: 0.83rem;
    }
  `,
  ImageSizer: styled.div`
    // Defines the ratio of the image, the padding is in % of the width of the Wrapper
    padding-top: 60%;
  `,
  Image: styled(Img)`
    // The Image takes the place of the Sizer and keep its ratio
    // !important is needed to override Gatsby Img inline style (position: relative)
    position: absolute !important;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  `,
  Content: styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
  `,
  Dates: styled(S1)`
    margin-bottom: 5px;
  `,
  Title: styled(H5)`
    margin-bottom: 0.7rem;
    @media screen and (max-width: ${MOBILE_MAX_WIDTH}px) {
      margin-bottom: 1.3rem;
    }
  `,
  Categories: styled.div`
    display: flex;
    flex-direction: row;
  `,
  //TODO: make proper category tags
  Category: styled(T3)`
    margin-right: 0.67rem;
    background-color: ${COLORS.BEIGE};
    padding: 6px 7px 7px 7px;
    text-transform: uppercase;
    font-weight: 400;
  `,
  Footer: styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    margin-top: auto;
  `,
  //TODO: Update when answer from Molly about this unknown component
  Free: styled(S1).attrs({ $textColor: "white" })`
    position: absolute;
    font-size: 10px;
    background-color: ${COLORS.BOTTLE_GREEN};
    padding: 6px 7px 7px 7px;
    bottom: 0px;
    right: 0px;
  `,
  Ticket: styled.div`
    position: absolute;
    display: flex;
    flex-direction: row;
    align-items: center;
    bottom: 0px;
    right: 0px;
  `,
  TicketIcon: styled(TicketIcon)`
    height: 0.56rem;
    margin-right: 0.5rem;
    margin-bottom: 2px;
    fill: ${COLORS.BOTTLE_GREEN};
  `,
  TicketText: styled(T4)``,
  ResetLink: styled(AutoLink)`
    text-decoration: inherit;
    color: inherit;
    border-bottom: 1px solid ${COLORS.BOTTLE_GREEN};
    :last-child {
      border-bottom: none;
    }
  `,
  Description: styled(T1)``,
}
